import React from "react";
import { graphql } from "gatsby";
// Component imports
import MyHabitsLayout from "../../components/my_habits/MyHabitsLayout";
import AppFAQFullItem from "../../components/app_pages/AppFAQFullItem";
//import ComingSoon from "../../components/common/ComingSoon";
import SEO from "../../components/common/seo";

// Image imports

// CSS imports
import "../../assets/sass/app_page.scss";

export default ({ data }) => {
  return (
    <MyHabitsLayout>
      <SEO
        title="My-Habits News"
        keywords={[`myhabits`, `my-habits`, "news"]}
      />

      <div className="app-navbar-padding">
        {/* <ComingSoon
                    title="My-Habits News"
                    description="The My-Habits News page will be released when the app is live on the store"
                    backPage="my_habits/my_habits"
                    backButtonTitle="Back"
                /> */}

        {data.faq_items.edges.map(({ node }) => {
          return (
            <AppFAQFullItem
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              content={node.html}
            />
          );
        })}
      </div>
    </MyHabitsLayout>
  );
};

export const query = graphql`
  {
    faq_items: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/md/my_habits/news_*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
          }
          id
          html
        }
      }
    }
  }
`;
